const initialCategoryState = {
    isNotificationThrowed: false,
    notification: {
        variant: '',
        message: '',
        icon: '',
        iconSize: 0,
        iconCustomStyles: {color: 'red'},
    }
};
const notifications = (state = initialCategoryState, action) => {
    const {
        data,
        type,
    } = action;
    switch (type) {
        case 'SET_NOTIFICATION_DATA':
            return { ...state, ...data };
        default:
            return state;
    }
};

export default notifications;
