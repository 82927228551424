import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import Icon from './Icon';

interface StyleButtonProps {
  layout?: string,
  selected?: boolean,
  size?: 'block' | 'large' | 'small',
  predefinedStyles?: 'default' | 'primary' | 'secundary',
  customStyle?: object,
  iconName?: string,
  iconColor?: string,
  iconSize?: number
  iconStyle?: object,
  hover?: any,
}

interface ButtonProps extends StyleButtonProps {
  type?: string,
  text: string,
  loading?: boolean,
  disabled?: boolean,
  hover?: any,
  onClick?(e: any): any
}

const StyledButton = styled.button<StyleButtonProps>`
  display: ${(props) => {
    if (props.predefinedStyles === 'default') return 'inline-block';
    return 'block';
  }};
  width: ${(props) => {
    if (props.predefinedStyles === 'default') return 'auto';
    return '100%';
  }};
  background-color: ${(props) => {
    if (props.predefinedStyles === 'default') return 'transparent';
    return props.theme.colors.biscay;
  }};
  color: ${(props) => {
    if (props.predefinedStyles === 'default') return props.theme.colors.mineShaftDarker;
    return props.theme.colors.white;
  }};
  font-size: ${(props) => {
    if (props.size === 'small') return '12px';
    return '20px';
  }};
  font-weight: bold;
  padding: ${(props) => {
    if (props.size === 'small') return '5px 15px';
    return '20px 0';
  }};
  border: 1px solid ${(props) => {
    if (props.predefinedStyles === 'default') return props.theme.colors.mineShaftDarker;
    return props.theme.colors.biscay;
  }};
  ${(props) => {
    if (props.hover) {
      return css`
        &:hover{
          ${props.hover};
        }
      `
    }
  }};
`;

const Bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const StyledDotsLoader = styled.div`
    display: inline-block;
`;

const Dot = styled.span`
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border-radius: 100%;
    display: inline-block;
    animation: ${Bounce} 1s infinite ease-in-out both;
    &:first-child {
        animation-delay: -0.32s;
    }
    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

const ButtonLoader = () => (
  <StyledDotsLoader>
    <Dot />
    <Dot />
    <Dot />
  </StyledDotsLoader>
);

const Button = (props: ButtonProps) => {

  const {
    layout,
    selected,
    loading,
    disabled,
    text,
    onClick,
    type,
    predefinedStyles,
    size,
    customStyle,
    iconName,
    iconColor,
    iconSize,
    iconStyle,
    hover,
  } = props;

  return (
    <StyledButton
      type={type}
      layout={layout}
      onClick={onClick}
      disabled={loading || disabled}
      selected={selected}
      predefinedStyles={predefinedStyles}
      size={size}
      style={customStyle}
      hover={hover}
    >
      {iconName && <Icon
        color={iconColor}
        size={iconSize}
        customStyle={iconStyle}
        icon={iconName ? iconName : ''}
      />
      }
      {loading ? <ButtonLoader /> : text}
    </StyledButton>
  );
}

Button.displayName = 'Button';

Button.defaultProps = {
  type: 'button',
  text: '',
  loading: false,
  disabled: false,
  selected: false,
};

export default Button;
