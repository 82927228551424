import React from 'react';
import styled from 'styled-components/macro';

interface StyledInputCheckBoxProps {
  error?: boolean
}

interface InputCheckBoxProps  extends StyledInputCheckBoxProps{
  layout?: string,
  name: string,
  label: string,
  checked?: boolean,
  styles?: object,
  onClick?(e: any): void,
  onChange?(e: any): void,
}

const InputCheckboxLabel = styled.label<StyledInputCheckBoxProps>`
  display: inline-block;
  position: relative;
  padding-left: 40px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 0.813rem;
  font-weight: 400;
  user-select: none;
  color: ${props => props.error ? props.theme.colors.red : props.theme.colors.mineShaft};
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
`;

const CheckMark = styled.span<StyledInputCheckBoxProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: solid 1px ${props => props.error ? props.theme.colors.red : props.theme.colors.silver};
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 10px;
    height: 15px;
    border: solid ${props => props.theme.colors.green};;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const InputCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${CheckMark}{
    border-color: ${props => props.theme.colors.green};
  }

  &:checked ~ ${CheckMark}:after {
    display: block;
  }
`;

const InputCheckBox = (props:InputCheckBoxProps) => {
  const {
    label,
    name,
    checked,
    styles,
    onClick,
    error,
    onChange
  } = props;

  return (
    <InputCheckboxLabel error={error} style={{...styles}}>
      <InputCheckbox onClick={onClick} onChange={onChange} checked={checked} name={name}/>
      <CheckMark error={error} />
      <span dangerouslySetInnerHTML={{__html: label}} />
    </InputCheckboxLabel>
  );
}

InputCheckBox.displayName = 'InputCheckBox';

export default InputCheckBox;
