import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Icon from '../../components/ui/Icon';

interface Params {
  lang: string;
}

const MaintenancePage = styled.div<{ bgImage: string }>`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.mineShaftDarker};
  background-image: url(${props => props.bgImage});
  height: 100vh;
  background-size: cover;
  background-position: center;
  text-align: center;
`;

const CenteredElements = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled(CenteredElements)`
  margin: 12px 0 12px 0;
`;

const WeAreInMaintenance = styled(CenteredElements)`
  font-size: 1.875rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1.625rem;
  }
  letter-spacing: 0.63px;
  font-weight: 600;
  margin: 12px 0;
`;

const DescriptionText = styled(CenteredElements)`
  font-size: 1.25rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
  letter-spacing: 0.42px;
  margin-top: 20px;
`;

const Box = styled.div`
  width: 728px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
`;

const LightBox = styled(Box)`
  background-color: ${props => props.theme.colors.pampas};
  padding-top: 33px;
  padding-bottom: 85px;
`;

const DarkBox = styled(Box)`
  background-color: ${props => props.theme.colors.mineShaftDarker};
`;

function Maintenance({ match }: RouteComponentProps<Params>) {

  const { i18n, t } = useTranslation();
  const lang = match.params.lang;
  i18n.changeLanguage(lang);

  const themeStyled = useContext(ThemeContext);

  const rand = Math.ceil(Math.random() * 3);
  const bgImage = require(`../../assets/images/errorPages/maintenance-${rand}.jpg`);

  return (
    <MaintenancePage bgImage={bgImage}>
      <Container maxWidth={false} >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid
            container
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LightBox>
              <IconWrapper>
                <Icon
                  icon={'tools-maintenance'}
                  color={themeStyled.colors.black}
                  size={48}
                />
              </IconWrapper>
              <WeAreInMaintenance>{t('Estamos de mantenimiento')}</WeAreInMaintenance>
              <DescriptionText>{t('Estamos mejorando nuestra web, volveremos lo antes posible.')}</DescriptionText>
              <DescriptionText>{t('Gracias por vuestra comprensión.')}</DescriptionText>
              <DescriptionText>{t('Si necesitas hablar con nosotros, puedes llamarnos al 919 01 15 89.')}</DescriptionText>
            </LightBox>
            <DarkBox>
              <IconWrapper>
                <Icon
                  icon={'logo'}
                  color={themeStyled.colors.textFooterColor}
                  customStyle={{
                    width: '179px',
                    height: '37px'
                  }}
                />
              </IconWrapper>
            </DarkBox>
          </Grid>
        </Grid>
      </Container >
    </MaintenancePage >
  );
}

export default Maintenance;
