import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Icon from './Icon';

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: props => ({
      backgroundColor: props.colorMineShaftDarker,
      padding: '10px 8px',
    })
  })
);

function GenericHeader() {
  const styledTheme = useContext(ThemeContext);
  const classes = useStyles({
    colorMineShaftDarker: styledTheme.colors.mineShaftDarker,
  });

  return (
    <Container maxWidth={false} className={classes.headerContainer}>
      <Grid container>
        <Grid item xs={5}>
          <Icon
            icon='logo'
            size={'70%'}
            color={styledTheme.colors.golden}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default GenericHeader;
