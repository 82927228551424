import { createGlobalStyle } from "styled-components";
import { colors as colorsDefault } from './theme';

const base = {
  gridSize: 12,
  gutterWidth: 1,
  outerMargin: 2
};

const fontFamily = 'Barlow Condensed, sans-serif';
const fontFamilyMedium = 'Barlow Condensed, sans-serif';
const fontFamilySecondary = 'Barlow Condensed, serif';
const fontFamilySecondaryMedium = 'Barlow Condensed, serif';
const fontFamilyTertiary = "Barlow Condensed, serif";
const fontFamilyTertiaryMedium = "Barlow Condensed, serif";

const colors = {
  ...colorsDefault,
  biscay: "#02BBEC",
  shiny: "#3accf3",
  golden: "#C82656",
  merino: "#faf9f7",
  cafeRoyale: "#C82656",
  keppel: "#02BBEC",
  keppel40: "rgba(58,204,243,0.4)",
  goldenButton: "#939394",
  ferra: "#e1e1e4",
  vinrouge: "#a2a2a7",
  grandis: "#02BBEC",
  grayDark: "#22222b",
  athensGray: "rgba(58,204,243,0.07)",
  alabaster: "rgb(247,247,247)",
  footerBgColor: '#2a2a2a',
  textFooterColor: '#fff',
  textFooterColorLight: 'rgba(255, 255, 255, 0.36)'
};

const secondaryColors = {
  blue10: "#ade7ff",
  blue20: "#61bcff",
  darkGrey: "#AEADAD",
};

const breakpoints = {
  xs: "0px",
  ms: "360px",
  sm: "600px",
  md: "960px",
  mdLegacy: "1199px",
  lg: "1280px",
  xl: "1920px"
};

interface StyleClosetTheme {
  base: {};
  breakpoints: {};
  fontFamily: string;
  fontFamilyMedium: string;
  fontFamilySecondary: string;
  fontFamilySecondaryMedium: string;
  fontFamilyTertiary: string;
  fontFamilyTertiaryMedium: string;
  colors: { [key in keyof typeof colors]: string };
  secondaryColors: { [key in keyof typeof secondaryColors]: string };
}

const theme: StyleClosetTheme = {
  base,
  breakpoints,
  fontFamily,
  fontFamilyMedium,
  fontFamilySecondary,
  fontFamilySecondaryMedium,
  fontFamilyTertiary,
  fontFamilyTertiaryMedium,
  colors,
  secondaryColors
};

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 1rem;
  }
  #campaign,
  #home,
  #destination,
  #destinationInfo,
  #destinationLandings {
    background-color: white;
    min-height: 100vh;
  }
  .styledRoot{
    &, input, select, textarea, h1, h2, h3, h4, h5, h6, .MuiTab-root{
      font-family: ${fontFamily};
    }
    hr{
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      border-top: 1px solid ${colors.silver};
    }
  }
  body.disableScroll {
    overflow: hidden;
  }
`;

export { theme };
