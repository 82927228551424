
interface TooltipObject {
  description: string,
  priceBeauty: string
}

interface InsuranceObject {
  id: string,
  type: string,
  multiple?: boolean,
  description: string,
  selected: boolean,
  package: string,
  price: number,
  priceDetails: string,
  priceBeauty: string,
}


const emptyTooltip: TooltipObject = {
  description: '',
  priceBeauty: ''
};

const initialCheckoutState = {
  summary: {
    product: {
      title: '',
      name: '',
      departure_city: '',
      departure_date: '',
      arrival_date: ''
    },
    offer: {
      days: 0,
    },
    price: {
      flightPriceBeauty: '',
      flightPricePerPaxBeauty: '',
      totalBeauty: '',
      totalPerPaxBeauty: ''
    },
    includes: [],
    category: {
      type: '',
      name: ''
    },
    details: {
      passengersCount: 0,
    },
    pax: {
      passengers: 0,
      adults: 0,
      children: 0,
      babies: 0
    },
    breakDown: {
      campaign: {
        description: '',
        priceBeauty: '',
        price: 0
      },
      accommodation: {
        description: '',
        priceBeauty: '',
        price: 0
      },
      insurances: [],
      options: [],
      donate: {},
      percentage: {
        currency: '',
        percentage: 0,
        price: 0
      },
      fees: {
        description: '',
        priceBeauty: '',
        price: 0,
        priceDetails: ''
      },
      discount: {},
    },
    flight: {}
  },
  tooltip: emptyTooltip,
  breakdown: {
    campaign: {
      description: '',
      priceBeauty: '',
      price: 0
    },
    accommodation: {
      description: '',
      priceBeauty: '',
      price: 0
    },
    insurances: [],
    options: [],
    donate: {
      description: '',
      priceBeauty: '',
      price: 0
    },
    paymentFee: {
      percentage: 0,
      price: 0,
      description: '',
      priceBeauty: '',
      priceDetails: ''
    },
    fees: {
      description: '',
      priceBeauty: '',
      price: 0,
      priceDetails: ''
    },
    discount: {
      description: '',
      priceBeauty: '',
      price: 0,
    },
  },
  insurance: {},
  passengers: [],
  payment: [],
  paymentMethodId: 1,
  voucherCode: ''
};

const checkout = (state = initialCheckoutState, action) => {
  const {
    data,
    type,
  } = action;

  // Breakdown:
  // id (id of option),
  // type (accommodation, insurance, supplements),
  // multiple (if its a multiple option)
  // title
  // selected
  // price
  // priceDetails
  // priceBeauty

  switch (type) {
    case 'SET_CHECKOUT_SUMMARY_DATA':
      return { ...state, summary: data }
    case 'SET_CHECKOUT_SUMMARY_TOOLTIP':
      return { ...state, tooltip: data }
    case 'CLEAN_CHECKOUT_SUMMARY_TOOLTIP':
      return { ...state, tooltip: emptyTooltip }
    case 'SET_CHECKOUT_BREAKDOWN_DATA':
      return { ...state, breakdown: { ...state.breakdown, ...data } }
    case 'ADD_CHECKOUT_BREAKDOWN_DATA':
      if (data.multiple) {
        return {
          ...state,
          breakdown: {
            ...state.breakdown,
            [data.type]: data.singleInArray ? [data] : [...state.breakdown[data.type], data]
          },
        };
      }
      // single
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          [data.type]: data
        },
      };
    case 'REMOVE_CHECKOUT_BREAKDOWN_DATA':
      if (data.single) {
        return {
          ...state,
          breakdown: {
            ...state.breakdown,
            [data.type]: {},
          },
          voucherCode: ''
        }
      }
      // multiple
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          [data.type]: state.breakdown[data.type].filter(el => el.id !== data.id),
        },
        voucherCode: ''
      };
    case 'SET_CHECKOUT_PASSENGERS_DATA':
      return { ...state, passengers: data };
    case 'SET_CHECKOUT_INSURANCE_DATA':
      const newBreakdown: any = state.breakdown;
      newBreakdown.insurances.map((insurance: InsuranceObject, index: number) => {
        if (data && data[insurance.package]) {
          const newPrice = parseFloat(data[insurance.package].TotalPremium);

          newBreakdown.insurances[index].price = newPrice;
          newBreakdown.insurances[index].priceBeauty = window.localeTools.currency.parseNumberLocal(newPrice);
        }

        return null;
      });
      return {
        ...state,
        breakdown: newBreakdown,
        insuranceData: data
      }
    case 'SET_CHECKOUT_PAYMENT_DATA':
      // Preselect CARD (1) or STRIPE (6) if available
      const preselectedMethods = data.methods && data.methods.length > 0 && data.methods.filter(method => method.id === 1 || method.id === 6);

      return { ...state, payment: data, paymentMethodId: preselectedMethods && preselectedMethods.length > 0 ? preselectedMethods[0].id : 0 };
    case 'SET_CHECKOUT_PAYMENT_METHOD_DATA':
      return { ...state, paymentMethodId: data };
    case 'SET_CHECKOUT_VOUCHER_CODE_DATA':
      return { ...state, voucherCode: data };
    default:
      return state
  }
}

export default checkout
