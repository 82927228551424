import React from 'react';
import styled from 'styled-components/macro';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../assets/icomoon.json';

interface IconProps {
  icon: string,
  color?: string,
  size?: any,
  customStyle?: object,
  onClick?(e: any): void,
};

interface IconWrapperProps {
  clickable: boolean;
}

export const IconWrapper = styled.div<IconWrapperProps>`
  display: inline-block;
  cursor: ${props => props.clickable ? 'pointer' : 'inherit'};
`;

const Icon = (props: IconProps) => {
  const {
    icon,
    color,
    size,
    onClick,
    customStyle
  } = props;

  return (
    <IconWrapper onClick={onClick} clickable={typeof onClick === 'function'} style={customStyle}>
      <IcomoonReact
        iconSet={iconSet}
        icon={icon}
        color={color || ''}
        size={size || '100%'}
      />
    </IconWrapper>
  );
};

export default Icon;
