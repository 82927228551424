export type ActionType =
  | 'SET_REGISTER_DATA'
  | 'REQUEST_REGISTER_USER'
  | 'RECEIVED_REGISTER_USER'
  | 'ERROR_REGISTER_USER'

  interface ActionProps {
    type: ActionType,
    isFetching?: boolean,
    data?: {} | any,
    error?: any,
    payload?: {} | any,
  }

const initialRegisterState = {
  isFetching: false,
};

const register = (state = initialRegisterState, action: ActionProps) => {
  switch (action.type) {

    case 'REQUEST_REGISTER_USER': {
      return {
        ...state,
        isFetching: true,
      };
    }

    case 'RECEIVED_REGISTER_USER': {
      return {
        ...state,
        isFetching: false,
      };
    }

    case 'ERROR_REGISTER_USER': {
      return {
        ...state,
        isFetching: false,
      };
    }

    default:
      return state
  }

};

export default register;
