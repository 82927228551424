import React from "react";
import { HashRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Error500 from './errorPages/Error500';
import Maintenance from './errorPages/Maintenance';
import PreferenceCenter from './PreferenceCenter';

function App() {
  return (
    <>
      <HashRouter>
        <Switch>
          <Route path={`/:lang(en|es|de|fr)/error500`} component={Error500} />
          <Route path={`/:lang(en|es|de|fr)/maintenance`} component={Maintenance} />
          <Route path={`/:lang(en|es|de|fr)/preference-center`} component={PreferenceCenter} />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
