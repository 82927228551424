export type ActionType =
  | 'SET_AIRPORTS'
  | 'REQUEST_AIRPORTS'
  | 'RECEIVED_AIRPORTS'
  | 'ERROR_AIRPORTS';

interface ActionProps {
  type: ActionType,
  isFetching?: boolean,
  data?: {} | any,
  error?: any,
  payload?: {} | any,
}

const initialFlightsActionState = {
  isFetching: true,
  data: {
    content:{
      availableAirports:[]
    }
  },
  error: {},
}

const Airports = (state = initialFlightsActionState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_AIRPORTS': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_AIRPORTS': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {...payload},
      };
    }

    case 'ERROR_AIRPORTS': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
}

export default Airports;
