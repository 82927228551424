export type ActionType =
  | 'SET_LOG_DATA'
  | 'REQUEST_LOG_USER'
  | 'RECEIVED_LOG_USER'
  | 'ERROR_LOG_USER'
  | 'CHECK_IF_USER_IS_AUTHENTICATED'
  | 'DISCONNECT_USER';

interface ActionProps {
  type: ActionType,
  isFetching?: boolean,
  data?: {} | any,
  error?: {} | any,
  payload?: {} | any,
}

const initialAuthState = {
  isFetching: false,
  isLogging: false,
  data: {},
  isAuthenticated: false,
  error: {}
};

const auth = (state = initialAuthState, action: ActionProps) => {
  switch (action.type) {
    case 'CHECK_IF_USER_IS_AUTHENTICATED': {
      /* const {
        //isAuthenticated = false,
        //email = { email: '' },
      } = action */;

      return {
        ...state,
        //isAuthenticated,
      };
    }

    case 'DISCONNECT_USER': {
      return {
        ...state,
        isAuthenticated: false,
        data: initialAuthState.data
      };
    }

    case 'REQUEST_LOG_USER': {
      return {
        ...state,
        isLogging: true,
      };
    }

    case 'RECEIVED_LOG_USER': {
      const {
        customerId = '',
      } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        customerId: customerId,
        isLogging: false,
      };
    }

    case 'ERROR_LOG_USER': {

      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
      };
    }

    default:
      return state
  }

};

export default auth;
