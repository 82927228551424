import { createGlobalStyle } from "styled-components";

const base = {
  gridSize: 12,
  gutterWidth: 1,
  outerMargin: 2
};

const fontFamily = "futura-pt, sans-serif";
const fontFamilyMedium = "futura-pt-bold, sans-serif";
const fontFamilySecondary = "utopia-std, serif";
const fontFamilySecondaryMedium = "utopia-std-display, serif";
const fontFamilyTertiary = "utopia-std-subhead, serif";
const fontFamilyTertiaryMedium = "utopia-std-headline, serif";

export const colors = {
  black: "#000000",
  bianca: "#FDFBF5",
  emperor: "#535353",
  emperor38: "rgba(84, 77, 77, 0.38)",
  ferra: "#e5e1d6",
  ferra5: "rgba(229, 225, 214, 0.5)",
  ferra10: "rgba(108, 80, 71, 0.1)",
  ferra50: "rgba(108, 80, 71, 0.5)",
  ferraLight: "rgba(229, 225, 214, 0.4)",
  black00: "rgba(0,0,0,0)",
  black72: "rgba(0,0,0, .72)",
  black92: "rgba(0,0,0, .92)",
  black20: "rgba(0, 0, 0, 0.2)",
  flamePea: "#DF5239",
  red: "#DE0000",
  gray: "#666666",
  gray50: "rgba(133, 133, 133, 0.5)",
  grayDark: "#544d4d",
  grayDark80: "rgba(84,77,77,0.08)",
  green: "#009b76",
  mineShaft: "#333333",
  mineShaftDarker: "#2a2a2a",
  mineShaftDarker04: "rgba(42,42,42,0.4)",
  mineShaftDarker4: "rgba(42,42,42,0.04)",
  mineShaftDarker58: "rgba(42,42,42,0.58)",
  pearlBush: "#EDE6DB",
  softAmber50: "rgba(214,199,182,0.5)",
  softAmber20: "rgba(27, 48, 111, 0.07)",
  quarterPearlLusta: "#FFFDF7",
  white: "#FFFFFF",
  white50: "rgba(255,255,255,0.5)",
  white95: "rgba(255,255,255,0.95)",
  white9: "rgba(255,255,255,0.09)",
  whiteCreamy: "#faf9f7",
  whiteGrey: "#f3f2f0",
  cafeRoyale: "#998255",
  golden: "#b19662",
  goldenDarker: "#998255",
  golden5: "rgba(177, 150, 98, 0.5)",
  golden36: "rgba(177, 150, 98, 0.36)",
  goldenText: "#9b907a",
  metalGolden: '#836B3B',
  goldenButton: "#c1aa90",
  sorrelBrown: "#c9b287",
  grandis: "#ffd585",
  grandis15: "rgb(255,213,133, 0.15)",
  indianKhaki: "#c5b88e",
  purple: "#ac3d5b",
  brightSun: "#facb47",
  whiteLinen: "#f7f3e7",
  merino: "#f7f1e9",
  lightGrey: "rgba(214,199,182, 0.2)",
  lightGrey15: "rgba(216,216,216, 0.15)",
  lightGreyHex: "#d8d8d8",
  alabaster: "#fafafa",
  alabaster6: "rgba(146, 142, 142, 0.06)",
  chambray: "#3A5593",
  dustyGray: "#979797",
  dustyGray20: "rgba(151,151,151, 0.2)",
  dustyGray18: "rgba(151,151,151, 0.18)",
  hibiscus: "#c22e62",
  hibiscus20: "rgba(194,46,98, 0.2)",
  mercury: "#e9e9e9",
  mercuryLight: '#e5e5e5',
  biscay: "#1b306f",
  whiteOpacity: "rgba(42, 42, 42, 0.02)",
  springWood: "#FBFAF7",
  fireBush: "#E58332",
  greenHaze: "#009c5c",
  vinrouge: "#95415e",
  ferraGrayDark18: "rgba(84,77,77,0.18)",
  bizarre: "#efe2d7",
  grayNickel: "#c3c3c2",
  athensGray: "#EFF0F4",
  pampasDark: "#f3f1ec",
  pampas: "#F8F5F3",
  quillGray: "#dedddb",
  marzipan: "#f9dca3",
  putty: "#E8CB91",
  albescentWhite: "#f5e8cf",
  potPourry25: "rgba(241, 227, 216, 0.25)",
  roseBudCherry: "#3ebfbf",
  razzmatazz: "#ec1359",
  lowGrey: "#929292",
  mediumGrey: "#5f5f5f",
  shiraz: "#BD0F47",
  shirazDark: "#a40733",
  shiraz83: "rgba(164, 7, 51, 0.83)",
  cyprus: "#003e40",
  gallery: "#ededed",
  jaffa: "#ec983c",
  jaffa20: "rgba(236, 152, 60, 0.2)",
  silver: "#CCCCCC",
  keppel: "#32B397",
  keppel40: "rgba(50,179,151, 0.4)",
  solidPink: "#903849",
  shiny: "#385DCB",
  redBright: "#B10104",
  mateak26: "rgba(177,150,98, 0.26)",
  teak15: "rgba(215,200,183, 0.15)",
  ferraV2: "#6c5047",
  dawnPink: "#F2ECE4",
  tana40: "rgba(223,214,193, 0.4)",
  silverChalice: '#AAAAAA',
  yoBarTitle: "#186069",
  roseWood: "#570000",
  temptress: "#470000",
  heroPromoButton: "#faa21b",
  pearlLusta: "#FBEDDD",
  concrete: '#faf9f7',
  cinderella: '#FCDFD9',
  faluRed: '#791414',
  woodyBrown: '#43302D',
  froly: '#F7827D',
  peachSchnapps: '#FFDBD4',
  elm: '#1a917b',
  doveGray: '#626262',
  tundora: 'rgba(64, 64, 64, 1)',
  tundora50: 'rgba(64, 64, 64, 0.5)',
  regalBlue: '#003a75',
  drawerBg: '#F4F4F4',
  blueZodiac: '#0e1e4e',
  footerBgColor: '#2a2a2a',
  textFooterColor: '#b19662',
  textFooterColorLight: 'rgba(177, 150, 98, 0.36)',
  headerBackground: "#2a2a2a",
  headerFontColor: "#FFFFFF",
  terracota: '#e26d53',
  cararra: '#eae9e5',
  valencia: '#d73d3f',
  coronaBackgroundColor: '#E8CB91',
  desert: '#f7f6f5',
  potpourri41: 'rgba(241, 227, 216, 0.41)',
};

const secondaryColors = {
  blue10: "#ade7ff",
  blue20: "#61bcff",
  darkGrey: "#AEADAD"
};

const breakpoints = {
  xs: "0px",
  ms: "360px",
  sm: "600px",
  md: "960px",
  mdLegacy: "1199px",
  lg: "1280px",
  xl: "1920px"
};

interface StyleClosetTheme {
  base: {};
  breakpoints: {};
  fontFamily: string;
  fontFamilyMedium: string;
  fontFamilySecondary: string;
  fontFamilySecondaryMedium: string;
  fontFamilyTertiary: string;
  fontFamilyTertiaryMedium: string;
  colors: { [key in keyof typeof colors]: string };
  secondaryColors: { [key in keyof typeof secondaryColors]: string };
}

const theme: StyleClosetTheme = {
  base,
  breakpoints,
  fontFamily,
  fontFamilyMedium,
  fontFamilySecondary,
  fontFamilySecondaryMedium,
  fontFamilyTertiary,
  fontFamilyTertiaryMedium,
  colors,
  secondaryColors
};

export const GlobalStyle = createGlobalStyle`
  *, :after, :before {
      box-sizing: border-box;
  }
  #campaign,
  #home,
  #destination,
  #destinationInfo,
  #destinationLandings {
    background-color: white;
    min-height: 100vh;
  }
  .styledRoot{
    &, input, button, select, textarea, h1, h2, h3, h4, h5, h6, .MuiTab-root{
      font-family: ${fontFamily};
    }
    hr{
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      border-top: 1px solid ${colors.silver};
    }
  }
  body{
    margin: 0;
    &.disableScroll {
      overflow: hidden;
    }
  }
`;

export { theme };
