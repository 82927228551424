import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import auth from './auth';
import header from './header';
import footer from './footer';
import register from './register';
import loading from './loading';
import checkout from './checkout';
import market from './market';
import orderConfirm from './confirmation';
import campaign from './campaign';
import category from './category';
import home from './home';
import airports from './airports';
import flights from './flights';
import landing from './landing';
import destination from './destination';
import destinationInfo from './destinationInfo';
import destinationPoi from './destinationPoi';
import budget from './budget';
import cancellationVoucher from './cancellationVoucher';
import orderAttempt from './orderAttempt';
import cookies from "./cookies";
import genericPages from "./genericPages";
import notifications from './notifications';
import promotion from './promotion';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    register,
    loading,
    header,
    footer,
    checkout,
    market,
    orderConfirm,
    campaign,
    category,
    home,
    airports,
    landing,
    destination,
    destinationInfo,
    destinationPoi,
    budget,
    cancellationVoucher,
    orderAttempt,
    cookies,
    genericPages,
    notifications,
    flights,
    promotion,
  });
