export type ActionType =
  | 'SET_PROMOTION_DATA'
  | 'REQUEST_PROMOTION_DATA'
  | 'RECEIVED_PROMOTION_DATA'
  | 'IS_CACHED_PROMOTION_DATA'
  | 'ERROR_PROMOTION_DATA';


interface ActionProps {
  type: ActionType,
  isFetching?: boolean,
  data?: {} | any,
  error?: any,
  payload?: {} | any,
}

export interface PromotionDiscountObject {
  code: string,
  value: number,
  minumum: number
}

export interface PromotionStylingYoBarObject {
  icon: string,
  iconAlternate?: string,
  iconSize: number,
  iconColor?: string,
  iconFlash?: string,
  iconDiscount?: string,
  titleColor?: string,
  arrowUp?: string,
  arrowDown?: string,
  arrowLeft?: string,
  arrowRight?: string,
  background: string,
  backgroundColor: string,
  buttonTextColor: string,
  lineColor?: string,
}

export interface PromotionStylingModalObject {
  icon: string,
  headerIcon?: string,
  background: string,
  backgroundColor: string,
  color?: string,
  spanColor?: string,
  codeColor?: string,
  discountColor?: string,
  discountIcon?: string,
  buttonBackgroundColor?: string, 
  flashBackgroundColor?: string,
  flashColor?: string,
  flashIcon?: string,
  separatorIcon?: string,
  logo?: string,
  logoWidth?: string,
  descriptionColor?: string,
  columnTitleColor?: string,
  columnIconOne?: string,
  columnIconTwo?: string,
  columnIconThree?: string,
  columnIconSize?: number,
  arrowLeft?: string,
  arrowRight?: string,
  footerBackgroundColor?: string,
}

export interface PromotionStylingProductObject {
  icon: string,
  color: string,
  spanColor?: string,
  countdownBackgroundColor?: string,
  cardBackground?: string,
  cardBackgroundColor?: string,
}

export interface PromotionStylingCheckoutObject {
  icon: string,
  color: string,
  bannerBackground: string,
  bannerBackgroundColor: string,
  bannerColor: string,
  spanColor: string,
  codeColor: string
}

export interface PromotionStylingHomeObject {
  image: string,
}

export interface PromotionStylingLandingObject {
  image: string,
  colorTitle?: string,
  colorSubTitle?: string,
  colorDescription?: string,
}

export interface PromotionObject {
  enabled: boolean,
  flash: boolean,
  discount: boolean,
  product: boolean,
  landing: boolean,
  discounts: PromotionDiscountObject[],
  styling: {
    yoBar: PromotionStylingYoBarObject,
    modal: PromotionStylingModalObject,
    product: PromotionStylingProductObject,
    checkout?: PromotionStylingCheckoutObject,
    home?: PromotionStylingHomeObject,
    landing?: PromotionStylingLandingObject,
  }
}

interface PromotionStateObject {
  isFetching: boolean,
  data: PromotionObject,
  error: any,
}

const initialPromotionState: PromotionStateObject = {
  isFetching: true,
  data: {
    enabled: false,
    flash: false,
    discount: false,
    product: false,
    landing: false,
    discounts: [],
    styling: {
      yoBar: {
        icon: '',
        iconSize: 0,
        background: '',
        backgroundColor: '',
        buttonTextColor: ''
      },
      modal: {
        icon: '',
        background: '',
        backgroundColor: '',
      },
      product: {
        icon: '',
        color: ''
      }
    }
  },
  error: {},
}

const Promotion = (state = initialPromotionState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_PROMOTION_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_PROMOTION_DATA':
    case 'IS_CACHED_PROMOTION_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {...payload},
      };
    }

    case 'ERROR_PROMOTION_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
}

export default Promotion;
