import React, { useState, useEffect } from "react";

interface TranslationsContextProps {
  checkout: any;
  myaccount: any;
  confirm: any;
  campaign: any;
  home: any;
  modal: any;
  landing: any;
  destination: any;
  budget: any;
  cancellationVoucher: any;
  promotion: any;
  cookies: any;
  genericPages: any;
  errorPages: any;
  flightSimulator: any;
  header: any;
  footer: any;
  aboutUs: any;
  faq: any,
}

const DEFAULT_TRANSLATIONS: TranslationsContextProps = {
  checkout: {},
  myaccount: {},
  confirm: {},
  campaign: {},
  home: {},
  modal: {},
  landing: {},
  destination: {},
  budget: {},
  cancellationVoucher: {},
  promotion: {},
  cookies: {},
  flightSimulator: {},
  genericPages: {},
  errorPages: {},
  header: {},
  footer: {},
  aboutUs: {},
  faq: {}
};

window.localeTools = window.localeTools || {};

const TranslationsContext = React.createContext(DEFAULT_TRANSLATIONS);

interface TranslationsProviderProps {
  children: object;
}

const TranslationsProvider = (props: TranslationsProviderProps) => {
  const localeToolTexts = window.localeTools.texts;
  const [translations, setTranslations] = useState(DEFAULT_TRANSLATIONS);

  useEffect(() => {
    setTranslations({ ...translations, ...localeToolTexts });
    // eslint-disable-next-line
  }, []);

  return (
    <TranslationsContext.Provider value={translations}>
      {props.children}
    </TranslationsContext.Provider>
  );
};
export { TranslationsContext, TranslationsProvider };
