import { createGlobalStyle } from "styled-components";
import { colors as colorsDefault } from './theme';

const base = {
  gridSize: 12,
  gutterWidth: 1,
  outerMargin: 2
};

const fontFamily = 'Quatro, sans-serif';
const fontFamilyMedium = 'QuatroMedium, sans-serif';
const fontFamilySecondary = 'QuatroSlab, serif';
const fontFamilySecondaryMedium = 'QuatroSlabMedium, serif';
const fontFamilyTertiary = "QuatroItalic, serif";
const fontFamilyTertiaryMedium = "QuatroMediumItalic, serif";

const colors = {
  ...colorsDefault,
  biscay: "#FF8C00",
  shiny: "rgb(253, 168, 65)",
  golden: "#FF8C00",
  merino: "#faf9f7",
  cafeRoyale: "#FF8C00",
  keppel: "#FF8C00",
  keppel40: "rgba(255,140,0,0.4)",
  goldenButton: "#939394",
  ferra: "#e1e1e4",
  vinrouge: "#a2a2a7",
  grandis: "#FF8C00",
  grayDark: "#22222b",
  athensGray: "rgba(255,140,0,0.07)",
  alabaster: "rgb(247,247,247)",
  footerBgColor: '#2a2a2a',
  textFooterColor: '#fff',
  textFooterColorLight: 'rgba(255, 255, 255, 0.36)'
};

const secondaryColors = {
  blue10: "#ade7ff",
  blue20: "#61bcff",
  darkGrey: "#AEADAD",
};

const breakpoints = {
  xs: "0px",
  ms: "360px",
  sm: "600px",
  md: "960px",
  mdLegacy: "1199px",
  lg: "1280px",
  xl: "1920px"
};

interface StyleClosetTheme {
  base: {};
  breakpoints: {};
  fontFamily: string;
  fontFamilyMedium: string;
  fontFamilySecondary: string;
  fontFamilySecondaryMedium: string;
  fontFamilyTertiary: string;
  fontFamilyTertiaryMedium: string;
  colors: { [key in keyof typeof colors]: string };
  secondaryColors: { [key in keyof typeof secondaryColors]: string };
}

const theme: StyleClosetTheme = {
  base,
  breakpoints,
  fontFamily,
  fontFamilyMedium,
  fontFamilySecondary,
  fontFamilySecondaryMedium,
  fontFamilyTertiary,
  fontFamilyTertiaryMedium,
  colors,
  secondaryColors
};

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 0.875rem;
  }
  #campaign,
  #home,
  #destination,
  #destinationInfo,
  #destinationLandings {
    background-color: white;
    min-height: 100vh;
  }
  .styledRoot{
    &, input, select, textarea, h1, h2, h3, h4, h5, h6, .MuiTab-root {
      font-family: ${fontFamily};
    }
    hr{
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      border-top: 1px solid ${colors.silver};
    }
  }
  body.disableScroll {
    overflow: hidden;
  }
  /* Overwrite font-weight for 600 and bold */
  @font-face {
    font-family:"quatro";
    src:url("https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
        url("https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
        url("https://use.typekit.net/af/25b75a/00000000000000003b9afc06/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:600;
  }
`;

export { theme };
