import _ from 'lodash';

export type ActionType =
  | 'SET_DEPARTURE_CITY'
  | 'SET_DATES'
  | 'SET_PASSENGERS'
  | 'SET_FLIGHT_ID'
  | 'SET_FLIGHT_DAYS'
  | 'REQUEST_FLIGHT_DAYS'
  | 'RECEIVED_FLIGHT_DAYS'
  | 'ERROR_FLIGHT_DAYS'
  | 'SET_FLIGHTS_LIST'
  | 'REQUEST_FLIGHTS_LIST'
  | 'RECEIVED_FLIGHTS_LIST'
  | 'IS_CACHED_FLIGHTS_LIST'
  | 'DELETE_FLIGHTS_LIST'
  | 'ERROR_FLIGHTS_LIST';

interface ActionProps {
  type: ActionType,
  isFetchingFlights?: boolean,
  isFetchingDates?: boolean,
  data?: {} | any,
  error?: any,
  payload?: {} | any,
}

const initialFlightsActionState = {
  isFetching: true,
  isFetchingFlights: true,
  isFetchingDates: true,
  isCachedFlights: false,
  data: {
    passengers: {
      adults: 2,
      children: 0,
      babies: 0
    },
    departureCity: '',
    departureDate: '',
    returnDate: '',
    days: [],
    list: [],
    orderedByPriceFlightList: [],
    orderedByDurationFlightList: [],
    searchTime: 0,
    errorMessage: '',
  },
  error: {},
}

const ORDER_BY_PRICE = 'price';
const ORDER_BY_DURATION = 'durationMins';

const Flights = (state = initialFlightsActionState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'SET_DEPARTURE_CITY': {
      const { payload = {} } = action;

      return {
        ...state,
        data: {
          ...state.data,
          departureCity: payload
        }
      }
    }

    case 'SET_DATES': {
      const { payload = {} } = action;

      return {
        ...state,
        data: {
          ...state.data,
          departureDate: payload.departureDate,
          returnDate: payload.returnDate,
          list: [],
          orderedByPriceFlightList: [],
          orderedByDurationFlightList: [],
        }
      }
    }

    case 'SET_PASSENGERS': {
      const { payload = {} } = action;

      return {
        ...state,
        data: {
          ...state.data,
          passengers: {
            ...state.data.passengers,
            ...payload
          }
        }
      }
    }

    case 'SET_FLIGHT_ID': {
      const { payload = {} } = action;

      return {
        ...state,
        data: {
          ...state.data,
          flightId: payload
        }
      }
    }

    case 'REQUEST_FLIGHT_DAYS': {
      return {
        ...state,
        isFetchingDates: true,
        data: {
          ...state.data,
          days: [],
          list: [],
          orderedByPriceFlightList: [],
          orderedByDurationFlightList: [],
        },
        error: {}
      };
    }

    case 'REQUEST_FLIGHTS_LIST': {
      return {
        ...state,
        isFetchingFlights: true,
        data: {
          ...state.data,
          list: [],
          orderedByPriceFlightList: [],
          orderedByDurationFlightList: [],
        },
        error: {}
      };
    }

    case 'RECEIVED_FLIGHT_DAYS': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetchingDates: false,
        data: {
          ...state.data,
          days: payload
        }
      };
    }

    case 'RECEIVED_FLIGHTS_LIST': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetchingFlights: false,
        isCachedFlights: false,
        data: {
          ...state.data,
          list: payload.flights,
          orderedByPriceFlightList: _.orderBy(payload.flights, ORDER_BY_PRICE),
          orderedByDurationFlightList: _.orderBy(payload.flights, ORDER_BY_DURATION),
          searchTime: payload.searchTime,
          errorMessage: payload?.error,
        }
      };
    }

    case 'IS_CACHED_FLIGHTS_LIST': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetchingFlights: false,
        isCachedFlights: true,
        data: {
          ...state.data,
          list: payload,
          orderedByPriceFlightList: _.orderBy(payload, ORDER_BY_PRICE),
          orderedByDurationFlightList: _.orderBy(payload, ORDER_BY_DURATION),
          searchTime: 0,
          errorMessage: payload?.error,
        }
      };
    }

    case 'DELETE_FLIGHTS_LIST': {
      return {
        ...state,
        data: {
          ...state.data,
          list: []
        }
      };
    }

    case 'ERROR_FLIGHT_DAYS': {
      const { error = {} } = action;
      return {
        ...state,
        isFetchingDates: false,
        error: { ...error },
      };
    }

    case 'ERROR_FLIGHTS_LIST': {
      const { error = {} } = action;
      return {
        ...state,
        isFetchingFlights: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
}

export default Flights;
