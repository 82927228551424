import 'core-js/es';
import 'react-app-polyfill/ie9';
import smoothScrollPolyfill from 'smoothscroll-polyfill';
import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ScrollToTop from 'react-router-scroll-top';

import { TranslationsProvider } from "./context/TranslationsContext";

import { ThemeProvider } from 'styled-components/macro';
import { theme as themeDefault, GlobalStyle as GlobalStyleDefault } from './themes/theme';
import { theme as themeSecretEscapes, GlobalStyle as GlobalStyleSecretEscapes } from './themes/themeSecretEscapes';
import { theme as themeJournaway, GlobalStyle as GlobalStyleJournaway } from './themes/themeJournaway';

// Translate imports
import { I18nextProvider } from 'react-i18next';

// Style imports
import './index.css';

// Components imports
import App from './containers/App';

// Store imports
import configureStore, { history } from './store/configureStore';

// Import translates
import i18n from './i18n';

import * as serviceWorker from './serviceWorker';

smoothScrollPolyfill.polyfill();
(window as any).__forceSmoothScrollPolyfill__ = true;

// Config store and save into window
const store = configureStore();

// Load the theme
const affiliateIdElement: HTMLInputElement = document.getElementById('affiliateId') as HTMLInputElement;
const affiliateId: string = affiliateIdElement ? affiliateIdElement.value : '1';

let theme;
let GlobalStyle; // Component
switch (affiliateId) {
  case '2':
    theme = themeSecretEscapes;
    GlobalStyle = GlobalStyleSecretEscapes;
    break;
  case '40':
    theme = themeJournaway;
    GlobalStyle = GlobalStyleJournaway;
    break;
  default:
    theme = themeDefault;
    GlobalStyle = GlobalStyleDefault;
    break;
}

function IndexWrapper(Component) {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <div className='styledRoot'>
            <GlobalStyle />
            <ConnectedRouter history={history}>
              <div>
                <Router>
                  <Switch>
                    <Route path={'/*'} component={Component} />
                  </Switch>
                </Router>
              </div>
            </ConnectedRouter>
          </div>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
}

if (document.getElementById('root')) {
  ReactDOM.render(IndexWrapper(App), document.getElementById('root'));

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/App', () => {
      const NextApp = require('./containers/App').default;
      //@ts-ignore
      ReactDOM.render(IndexWrapper(NextApp), document.getElementById('root'));
    });
  }
}


function CheckoutComponentWrapper(Component) {
  const historyBack = document.getElementById('checkout')!.getAttribute('data-history-back')!;
  const baseUrl = document.getElementById('checkout')!.getAttribute('data-base-url')!;

  return (
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <TranslationsProvider>
                <div className='styledRoot'>
                  <GlobalStyle />
                  <Component historyBack={historyBack} baseUrl={baseUrl} />
                </div>
              </TranslationsProvider>
            </ThemeProvider>
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </Suspense>
  );
}

const checkoutSummary = document.getElementById('checkout');
if (checkoutSummary) {
  const Checkout = React.lazy(() => import('./containers/Checkout'));
  ReactDOM.render(CheckoutComponentWrapper(Checkout), document.getElementById('checkout'));

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Checkout', () => {
      const NextApp = require('./containers/Checkout').default;
      //@ts-ignore
      ReactDOM.render(CheckoutComponentWrapper(NextApp), document.getElementById('checkout'));
    });
  }
}

function ComponentWrapper(Component) {
  return (
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <TranslationsProvider>
                <div className='styledRoot'>
                  <GlobalStyle />
                  {Component}
                </div>
              </TranslationsProvider>
            </ThemeProvider>
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </Suspense>
  );
}

const checkoutRegister = document.getElementById('checkoutRegister');
if (checkoutRegister) {
  const CheckoutRegisterWrapper = React.lazy(() => import('./containers/CheckoutRegisterWrapper'));
  ReactDOM.render(ComponentWrapper(<CheckoutRegisterWrapper />), document.getElementById('checkoutRegister'));
  
  //@ts-ignore
  if (module.hot) {
    //@ts-ignore
    module.hot.accept('./containers/CheckoutRegisterWrapper', () => {
      const NextApp = require('./containers/CheckoutRegisterWrapper').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), document.getElementById('checkoutRegister'));
    });
  }
}


const header = document.getElementById('header');
if (header) {
  const Header = React.lazy(() => import('./containers/Header'));
  ReactDOM.render(ComponentWrapper(<Header />), document.getElementById('header'));
  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Header', () => {
      const NextApp = require('./containers/Header').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), document.getElementById('header'));
    });
  }
}

const footer = document.getElementById('footer');
if (footer) {
  const Footer = React.lazy(() => import('./containers/Footer'));
  ReactDOM.render(ComponentWrapper(<Footer />), document.getElementById('footer'));

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Footer', () => {
      const NextApp = require('./containers/Footer').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), document.getElementById('footer'));
    });
  }
}

const campaign = document.getElementById('campaign');
if (campaign) {
  const Campaign = React.lazy(() => import('./containers/Campaign'));
  ReactDOM.render(ComponentWrapper(<Campaign />), document.getElementById('campaign'));

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Campaign', () => {
      const NextApp = require('./containers/Campaign').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), document.getElementById('campaign'));
    });
  }
}


function ConfirmationWrapper(Component) {
  return (
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <TranslationsProvider>
                <div className='styledRoot'>
                  <GlobalStyle />
                  <Component />
                </div>
              </TranslationsProvider>
            </ThemeProvider>
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </Suspense>
  );
}

const confirmationModule = document.getElementById('principal_confirmacion');
if (confirmationModule) {
  const Confirmation = React.lazy(() => import('./containers/Confirmation'));
  ReactDOM.render(ConfirmationWrapper(Confirmation), document.getElementById('principal_confirmacion'));

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Confirmation', () => {
      const NextApp = require('./containers/Confirmation').default;
      //@ts-ignore
      ReactDOM.render(ConfirmationWrapper(NextApp), document.getElementById('principal_confirmacion'));
    });
  }
}

const home = document.getElementById('home');
if (home) {
  const Home = React.lazy(() => import('./containers/Home'));
  ReactDOM.render(ComponentWrapper(<Home />), home);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Home', () => {
      const NextApp = require('./containers/Home').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), home);
    });
  }
}

const landing = document.getElementById('landing');
if (landing) {
  const Landing = React.lazy(() => import('./containers/Landing'));
  const action = document.getElementById('landing')!.getAttribute('data-action')!;
  ReactDOM.render(ComponentWrapper(<Landing action={action} />), landing);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Landing', () => {
      const NextApp = require('./containers/Landing').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), landing);
    });
  }
}


const destination = document.getElementById('destinationLandings');
if (destination) {
  const Destination = React.lazy(() => import('./containers/Destination'));
  ReactDOM.render(ComponentWrapper(<Destination />), destination);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Destination', () => {
      const NextApp = require('./containers/Destination').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), destination);
    });
  }
}

const destinationInfo = document.getElementById('destinationInfo');
if (destinationInfo) {
  const DestinationInfo = React.lazy(() => import('./containers/DestinationInfo'));
  const isPoiPageEl = document.getElementById('isPoiPage') as HTMLInputElement;
  const isPoiPage = isPoiPageEl.value
  ReactDOM.render(ComponentWrapper(<DestinationInfo isPoiPage={isPoiPage} />), destinationInfo);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/DestinationInfo', () => {
      const NextApp = require('./containers/DestinationInfo').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), destinationInfo);
    });
  }
}


const budgetPdf = document.getElementById('budgetPdf');
if (budgetPdf) {
  const BudgetPdf = React.lazy(() => import('./containers/BudgetPdf'));
  ReactDOM.render(ComponentWrapper(<BudgetPdf />), budgetPdf);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/BudgetPdf', () => {
      const NextApp = require('./containers/BudgetPdf').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), BudgetPdf);
    });
  }
}

const cancellationVoucherPdf = document.getElementById('cancellationVoucherPdf');
if (cancellationVoucherPdf) {
  const CancellationVoucherPdf = React.lazy(() => import('./containers/CancellationVoucherPdf'));
  ReactDOM.render(ComponentWrapper(<CancellationVoucherPdf />), cancellationVoucherPdf);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/CancellationVoucherPdf', () => {
      const NextApp = require('./containers/CancellationVoucherPdf').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), BudgetPdf);
    });
  }
}


const cookies = document.getElementById('cookies');
const cookiesStorage = localStorage.getItem('cookie');
const isCookie = !!cookiesStorage;

if (cookies && !isCookie) {
  const CookieBanner = React.lazy(() => import('./containers/CookieBanner'));
  ReactDOM.render(ComponentWrapper(<CookieBanner />), cookies);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/CookieBanner', () => {
      const NextApp = require('./containers/CookieBanner').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), cookies);
    });
  }
}

const notifications = document.getElementById('notifications');

if (notifications) {
  const Notifications = React.lazy(() => import('./containers/Notifications'));
  ReactDOM.render(ComponentWrapper(<Notifications />), notifications);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept('./containers/Notifications', () => {
      const NextApp = require('./containers/Notifications').default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />, 'notifications'), notifications);
    });
  }
}

const genericPages = document.getElementById("genericPages");
if (genericPages) {
  const GenericPages = React.lazy(() => import('./containers/GenericPages'));
  ReactDOM.render(ComponentWrapper(<GenericPages />), genericPages);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept("./containers/GenericPages", () => {
      const NextApp = require("./containers/GenericPages").default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), genericPages);
    });
  }
}

const aboutUs = document.getElementById("aboutUs");
if (aboutUs) {
  const AboutUs = React.lazy(() => import('./containers/static/AboutUs'));
  ReactDOM.render(ComponentWrapper(<AboutUs />), aboutUs);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept("./containers/static/AboutUs", () => {
      const NextApp = require("./containers/static/AboutUs").default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), aboutUs);
    });
  }
}

const error404 = document.getElementById("error404");
if (error404) {
  const Error404 = React.lazy(() => import('./containers/errorPages/Error404'));
  ReactDOM.render(ComponentWrapper(<Error404 />), error404);

  //@ts-ignore
  if (module.hot) {
    //@ts-ignorex
    module.hot.accept("./containers/errorPages/Error404", () => {
      const NextApp = require("./containers/errorPages/Error404").default;
      //@ts-ignore
      ReactDOM.render(ComponentWrapper(<NextApp />), error404);
    });
  }
}

serviceWorker.unregister();
