import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from 'styled-components/macro';
import { ThemeContext } from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Icon from '../components/ui/Icon';
import InputRadio from '../components/ui/InputRadio';
import InputCheckBox from '../components/ui/InputCheckBox';
import GenericHeader from '../components/ui/GenericHeader';
import Button from '../components/ui/Button';

interface Params {
  lang: string;
}

const PreferenceCenterPage = styled.div`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.colors.mineShaftDarker};
  background-color: ${props => props.theme.colors.alabaster};
`;

const BoxContainer = styled.div`
  width: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.white};
  margin: 20px 0;
  border-top: solid 2px ${props => props.theme.colors.golden};
`;

const Title = styled.div`
  font-family: ${props => props.theme.fontFamilySecondary};
  font-size: 3rem;
  font-style: italic;
  line-height: 0.83;
  letter-spacing: 0.67px;
  margin-left: 20px;
`;

const InBoxTitle = styled.div`
  font-family: ${props => props.theme.fontFamilySecondary};
  font-size: 2rem;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: 0.44px;
  margin-top: 20px;
`;

const ListText = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.28px;
`;

const SubOption = styled.div<{ isLast?: boolean }>`
  ${props => !props.isLast && `
    border-bottom: solid 1px ${props.theme.colors.dustyGray};
  `}
  display: flex;
  padding-top: 23px;
  padding-bottom: 23px;
  width: 100%;
`;

const SubOptionFirtColumn = styled.div`
  width: 82%;
`;

const DeleteSubscriptionLink = styled.div`
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.53px;
  margin-right: 30px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 30px;
  }
  a{
    color: ${props => props.theme.colors.biscay};
  }
`;

const TextAreaWrapper = styled.div`
  width: 100%;
`;

const TextArea = styled.textarea`
  width: 86%;
  height: 96px;
  border: solid 1px ${props => props.theme.colors.silver};
  resize: none;
  box-shadow: 0px 4px 12px 0 rgba(0,0,0,0.2);
  font-size: 1rem;
  margin-bottom: 20px;
`;

function PreferenceCenter({ match }: RouteComponentProps<Params>) {

  const { i18n, t } = useTranslation();
  const lang = match.params.lang;
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const themeStyled = useContext(ThemeContext);

  const [openSomeNotifications, setOpenSomeNotifications] = useState(false);
  const [openNoneNotifications, setOpenNoneNotifications] = useState(false);

  return (
    <PreferenceCenterPage>
      <GenericHeader />
      <form>
        <Container maxWidth={false}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              container
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <Icon
                icon={'mail-unsuscribe'}
                size={70}
              />
              <Title>{t('¡Danos una oportunidad!')}</Title>
              <BoxContainer>
                <Container maxWidth={'md'}>
                  <Grid item>
                    <InBoxTitle>
                      {t('¿Cuáles de nuestros mensajes quieres seguir recibiendo?')}
                    </InBoxTitle>
                  </Grid>
                  <Grid
                    container
                    style={{
                      borderBottom: `solid 1px ${themeStyled.colors.dustyGray}`,
                      padding: '23px 0',
                    }}
                  >
                    <Grid item xs={10}>
                      <ListText dangerouslySetInnerHTML={{ __html: t('Deseo recibir <strong>todas las comunicaciones</strong> de Exoticca - No te pierdas ninguna oferta y mantente informado de lo último en tendencias de viajes (2-3 semana)') }} />
                    </Grid>
                    <Grid item xs={2}>
                      <InputRadio
                        name={'emailPrefs'}
                        value={'allNotifications'}
                        label={''}
                        onClick={() => { setOpenSomeNotifications(false); setOpenNoneNotifications(false); }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      borderBottom: `solid 1px ${themeStyled.colors.dustyGray}`,
                      padding: '23px 0',
                    }}
                  >
                    <Grid item xs={10}>
                      <ListText dangerouslySetInnerHTML={{ __html: t('Deseo recibir solo algunas comunicaciones') }} />
                    </Grid>
                    <Grid item xs={2}>
                      <InputRadio
                        name={'emailPrefs'}
                        value={'someNotifications'}
                        label={''}
                        onClick={() => { setOpenSomeNotifications(true); setOpenNoneNotifications(false); }}
                      />
                    </Grid>
                    <Grid
                      container
                      style={{
                        background: `${themeStyled.colors.alabaster}`,
                        paddingLeft: '60px',
                        margin: '23px 0',
                        display: openSomeNotifications ? 'block' : 'none',
                      }}
                    >
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('<strong>Ofertas para ti</strong> - Te informaemos semanalmente de los mejore productos en base a tus preferencias  (1-2 / semana)') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'dealsForYou'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('<strong>Oferta de la semana</strong> - Te informaremos únicamente de nuestro top ventas semanal (1 / semana)') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'dealOfTheWeek'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('<strong>Premium Newsletter</strong> - Te informaremos mensualmente de nuestro producto estrella (1 / mes)') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'premiumNewsletter'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption isLast>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('<strong>Contenido no comercial</strong> (Noticias de interés, información sobre viajes y contenido de nuestro blog)') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'nonComercialContent'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      borderBottom: `solid 1px ${themeStyled.colors.dustyGray}`,
                      padding: '23px 0',
                    }}
                  >
                    <Grid item xs={10}>
                      <ListText dangerouslySetInnerHTML={{ __html: t('Darme de baja de todas las comunicaciones') }} />
                    </Grid>
                    <Grid item xs={2}>
                      <InputRadio
                        name={'emailPrefs'}
                        value={'noneNotifications'}
                        label={''}
                        onClick={() => { setOpenSomeNotifications(false); setOpenNoneNotifications(true); }}
                      />
                    </Grid>
                    <Grid
                      container
                      style={{
                        background: `${themeStyled.colors.alabaster}`,
                        paddingLeft: '60px',
                        margin: '23px 0',
                        display: openNoneNotifications ? 'block' : 'none',
                      }}
                    >
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('Ya no estoy interesado') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'notInterested'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('Recibo demasiados mensajes ') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'tooManyEmails'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('Nunca me di de alta') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'neverApplied'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <SubOption isLast>
                        <SubOptionFirtColumn>
                          <ListText dangerouslySetInnerHTML={{ __html: t('Otro (Especificar)') }} />
                        </SubOptionFirtColumn>
                        <InputCheckBox
                          name={'otherReasons'}
                          label={''}
                          onClick={() => { }}
                        />
                      </SubOption>
                      <TextAreaWrapper>
                        <TextArea
                          name={'unsubscribeOtherReason'}
                        />
                      </TextAreaWrapper>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{ padding: '40px 0' }}
                    justify={'flex-end'}
                    alignItems={'center'}
                  >
                    <Grid item xs={12} sm={9}>
                      <DeleteSubscriptionLink>
                        <a href={'/'}>{t('Darse de baja de todas las suscripciones')}</a>
                      </DeleteSubscriptionLink>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        type={'submit'}
                        text={t('Guardar')}
                        customStyle={{
                          textTransform: 'uppercase',
                          borderRadius: '5px',
                          fontSize: '1.25rem',
                          fontWeight: 600,
                          lineHeight: 1.2,
                          letterSpacing: '1.67px',
                          padding: '16px',
                          cursor: 'pointer'
                        }}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </BoxContainer>
            </Grid>
          </Grid>
        </Container>
      </form>
    </PreferenceCenterPage >
  );
}

export default PreferenceCenter;
