const market = (state = { country: ''}, action) => {
  const {
    data,
    type,
  } = action;

  switch (type) {
    case 'SET_MARKET_DATA':
      return {...state, ...data};
    default:
      return state;
  }
};

export default market;
