import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Spanish from './locales/es_ES/translations.json';
import English from './locales/en_GB/translations.json';
import French from './locales/fr_FR/translations.json';
import German from './locales/de_DE/translations.json';

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: English,
      },
      fr: {
        translations: French,
      },
      de: {
        translations: German,
      },
      es: {
        translations: Spanish,
      },
    },
    fallbackLng: 'en_GB',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: false,
    },
  });

export default i18n;
