export type ActionType =
  | 'SET_HEADER_DATA'
  | 'REQUEST_HEADER_DATA'
  | 'RECEIVED_HEADER_DATA'
  | 'IS_CACHED_HEADER_DATA'
  | 'ERROR_HEADER_DATA';

interface ActionProps {
  type: ActionType,
  isFetching?: boolean,
  data?: {} | any,
  error?: any,
  payload?: {} | any,
}

const initialHeaderState = {
  isFetching: true,
  data: {},
  error: {},
}

const Header = (state = initialHeaderState, action: Partial<ActionProps>) => {
  switch (action.type) {
    case 'REQUEST_HEADER_DATA': {
      return {
        ...state,
      };
    }

    case 'RECEIVED_HEADER_DATA':
    case 'IS_CACHED_HEADER_DATA': {
      const { payload = {} } = action;
      return {
        ...state,
        isFetching: false,
        data: {...payload},
      };
    }

    case 'ERROR_HEADER_DATA': {
      const { error = {} } = action;
      return {
        ...state,
        isFetching: false,
        error: { ...error },
      };
    }

    default:
      return state;
  }
}

export default Header;
