const initialCategoryState = {
  conditions: '',
  includes: [],
  itinerary: {
    map: '',
    pdf: '',
    days: [
      {
        image: '',
        title: '',
        day: '',
        description: ''
      }
    ]
  },
  notIncludes: '',
  usefulInformation: [],
  transport: {
    airline: '',
    airplaneImages: [''],
    legs: [{}],
    logo: '',
    logoHorizontal: '',
    notes: '',
    type: '',
  },
  accommodation: []
};
const category = (state = initialCategoryState, action) => {
  const {
    data,
    type,
  } = action;

  switch (type) {
    case 'SET_CATEGORY_DATA':
      return { ...state, ...data };
    default:
      return state;
  }
};

export default category;
