import React from 'react';
import styled from 'styled-components/macro';

interface InputRadioProps {
  layout?: string,
  name: string,
  label: string,
  helpText?: string,
  onClick(e: any): void,
  onChange?(e: any): void,
  icon?: string,
  iconSize?: number,
  checked?: boolean,
  value?: any
}

const InputRadioLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 40px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 0.938rem;
  font-weight: 500;
  user-select: none;

  color: ${props => props.theme.colors.mineShaft};
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: solid 1px ${props => props.theme.colors.dustyGray};
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: 9px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.theme.colors.white};
  }
`;

const StyledInputRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${CheckMark}{
    border-color: ${props => props.theme.colors.gray};
    background-color: ${props => props.theme.colors.gray};
  }

  &:checked ~ ${CheckMark}:after {
    display: block;
  }
`;

const HelpText = styled.small`
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${props => props.theme.colors.grey};
`;

const IconImg = styled.img`
  margin-left: 10px;
`;

const InputRadio = (props: InputRadioProps) => {
  const {
    name,
    label,
    helpText,
    onClick,
    icon,
    iconSize,
    checked,
    onChange,
    value
  } = props;

  return (
    <InputRadioLabel>
      <StyledInputRadio onClick={onClick} name={name} checked={checked} onChange={onChange} value={value} />
      <CheckMark />
      <span dangerouslySetInnerHTML={{ __html: label }} />
      {icon &&
        <IconImg src={icon} height={iconSize} />
      }
      {helpText &&
        <HelpText dangerouslySetInnerHTML={{ __html: helpText }} />
      }
    </InputRadioLabel>
  );
}

InputRadio.displayName = 'InputRadio';

InputRadio.defaultProps = {
  iconSize: 25,
};

export default InputRadio;

